import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { from, map, mergeMap, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadImageApi {
  constructor(private httpClient: HttpClient) { }

  up(payload: any, type: 'book-cover' | 'avatar' | 'chapter-image', name: string, data: { userId?: string; bookId?: string; chapterId?: string }) {
    const url = `/v1/api/upload-image`;
    data = {
      ...data,
    };
    const myImage$ = from(this.toImageFile(payload, name));
    return myImage$.pipe(
      map((myImage) => {
        const formData = new FormData();
        formData.append('file', myImage);
        formData.append('type', type);
        formData.append('name', name);
        formData.append('data', JSON.stringify(data));
        formData.append('extension', 'webp');
        console.log('myImage', myImage, url);
        return formData;
      }),
      mergeMap((formData) => {
        return this.httpClient.post(`${url}`, formData, {
          reportProgress: true,
          observe: 'events',
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        });
      })
    );
    // return this.httpClient.post(`${url}`, formData, {
    //   reportProgress: true,
    //   observe: 'events',
    //   headers: {
    //     Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    //   },
    // });
  }

  private async toImageFile(blob: any, id: string) {
    // const res: Response = await fetch(base64);
    // const blob: Blob = await res.blob();
    // const myImage = new File([blob], id, { type: 'image/webp' });
    const myImage = new File([blob], id, { type: 'image/webp' });
    return myImage;
  }
}
